<template>

    <!-- <nav class="navbar navbar-expand-lg static-top mymargin"> -->
    <!-- <nav class="mymargin myback"> -->
    <nav class="myback">
      <div class="container">
        <a class="navbar-brand" href="/">
          <div class="mymargin">
          <img src="https://aiforusall.s3.amazonaws.com/peopleandTech.png" alt="..." height="91" width="105">
        </div>
        </a>
      </div>
    </nav>
    </template>
    <script>
    export default {
      name: 'Nav1',
      methods: {
      // Log the user in
      login() {
        this.$auth.loginWithRedirect();
      },
      // Log the user out
      logout() {
        this.$auth.logout({
          returnTo: window.location.origin
        });
      }
      }
    }
    </script>
    <style lang="scss" scoped>
    .navbar-dark .navbar-nav .nav-link{
        color:black!important
    }
    .mymargin {
      display:block;
      margin:10px;
      
  }
  .myback { // #D8F0D8 #93C5FD
    background-color: #93C5FD!important;
}
    </style>