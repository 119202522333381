<template>
    <!-- component add bkgnd below-->
<div class="h-screen md:flex">
	<div
		class="relative overflow-hidden md:flex w-2/3 bg-gradient-to-tr from-blue-800 to-purple-700 i justify-around items-center hidden bkgnd">
		<div>
			<h1 class="text-green text-8xl font-sans font-bold left-h1">AI should serve us.</h1>
			<h1 class="text-green text-4xl font-sans font-bold left-h1">Stay on top of AI news, editorials, analysis and special offers.</h1><br />
			<h2 class="text-green mt-1 left-h2 font-bold font-sans">Enter and submit the form or email us at</h2>
			<h2 class="text-green mt-1 left-h2 font-bold font-sans"><a href="mailto:list@aiforusall.com">list@aiforusall.com</a> to join our mailing list.</h2>
      <h2 class="text-green mt-1 left-h2-img font-sans"><img src="../assets/rightarrow.png" width="83" height="41"></h2>
		</div>
	</div>
	<div class="flex md:w-1/3 justify-center py-10 items-center bg-blue-300 formbox bkgnd">
        <label class="text-danger">{{ errorfield }}</label>
        <form @submit.prevent="handleSubmitForm">
			<h1 class="text-gray-800 mb-1 font-sans font-bold text-xl">Please enter your Name and Email</h1><br />
			<div class="flex items-center border-2 py-2 px-3 rounded-2xl mb-4 bg-green-500">

        <svg class="h-5 w-5 text-black-400"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
        </svg>

					<input class="pl-2 outline-none border-none font-sans bg-yellow-100" type="text" placeholder="your name" v-model="seller.name" required/>
			</div>
			<div class="flex items-center border-2 py-2 px-3 rounded-2xl mb-4 bg-green-500">
            <svg class="h-5 w-5 text-black-400"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="3" y="5" width="18" height="14" rx="2" />
              <polyline points="3 7 12 13 21 7" />
            </svg>
						<input class="pl-2 outline-none border-none font-sans bg-yellow-100" type="text" placeholder="your email address" v-model="seller.email" required />
			</div>		
			<button type="submit" class="block w-full bg-indigo-500 mt-4 py-2 rounded-2xl text-white font-semibold mb-2 font-sans">Submit</button>
		</form>
	</div>
</div>
</template>
<script>
import axios from "axios";

export default {
  name: 'home',
  data() {
      return {
          seller: {
              name: '',
              email: '',
              phone: '',
              offerid: '',
              clientip:'0.0.0.0',
          },
          errorfield:"",
          selected:"",
      }
  },
  methods: {
      async handleSubmitForm() {
          // await fetch("https://checkip.amazonaws.com/", {mode: 'no-cors'}).then(res => res.text()).then(data => {this.seller.clientip = data; console.log("Client IP:", data)
          // })
          const configruntime = require('../configruntime');
          const port = configruntime.apiport;
          let apiURL = 'https://aiforusall.com/api/create-seller';
          if (configruntime.environment == 'dev') {
              apiURL = 'http://localhost:' + port + '/api/create-seller';
          }
          // console.log("using apiURL:", apiURL);
          this.errorfield = "";
          axios.post(apiURL, this.seller).then(() => {
            this.$router.push('/confirm')
            this.seller = {
              name: '',
              email: '',
              phone: '',
              offerid: '',
              clientip:'0.0.0.0'
            }
          }).catch(error => {
            if (error.response) {
              this.errorfield = error.response.data;
            }
          });
      }
  }
}
</script>

<style scoped>
    .bkgnd {    
    background-image: url('../assets/worldmapcircuitboard-19op.png'); 
    background-size: cover;
    background-position: center;
	padding-bottom: 10%;
  }
  .left-h1 {
	font-size:30px;
	text-align: center;
  }
  .left-h2 {
	font-size:30px;
	text-align: center;
  }
  .left-h2-img {
	margin-left: auto;
    margin-right: auto;
    width: 8em;
  }
  /* unvisited link */
a:link {
  color: blue;
}

/* visited link */
a:visited {
  color: green;
}

/* mouse over link */
a:hover {
  color: red;
}

/* selected link */
a:active {
  color: blue;
}
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}
.formbox {
	padding-bottom: 10%;
}
/* .formbox-field {
	bg-green-300
} */

</style>