import Vue from 'vue'
import Router from 'vue-router'
// original using boostrap
// below using tailwind with side by side of text and input form
import Offer from '../views/Offer.vue'
import About from '../views/About.vue'
import Confirm from '../views/Confirm.vue'
import Error from '../views/Error.vue'
import EditComponent from '../components/EditComponent.vue'
import ListComponent from '../components/ListComponent.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/offer',
    },
    {
      path: '/offer',
      name: 'offer',
      component: Offer,
    },
    {
      path: '/about',
      name: 'about',
      component: About,
    },
    {
      path: '/confirm',
      name: 'confirm',
      component: Confirm,
    },
    {
      path: '/error',
      name: 'error',
      component: Error,
    },
    {
      path: '/edit:id',
      name: 'edit',
      component: EditComponent,
    },
    {
      path: '/list',
      name: 'list',
      component: ListComponent,
    },
    {
      path: '*',
      redirect: '/offer'
    }
  ]
})